import React from "react";
import {
	Accordion as ReachAccordion,
	AccordionItem as ReachAccordionItem,
	AccordionButton,
	AccordionPanel,
} from "@reach/accordion";
import { Paragraph } from "./typography";
import { Icon } from "~/components/ui/icons";
import { notEmpty } from "~/utils/misc";
import { useHash } from "~/hooks/common";
import clsx from "clsx";
import { spTrackWebInteraction } from "~/utils/tracking";
import { Image } from "~/components/ui/image";
import type { ImageProps } from "~/components/ui/image";

export type AccordionItem = {
	iconImage?: ImageProps;
	title: string;
	panel: string | React.ReactNode;
	id?: string;
	defaultOpen?: boolean;
};

type Props = {
	items: AccordionItem[];
	defaultIndicies?: number[];
	multiple?: boolean;
	variant?: "default" | "sidebar";
};

export function Accordion({
	items,
	multiple = true,
	variant = "default",
}: Props) {
	const [indices, setIndices] = React.useState(getDefaultIndicies);

	const hash = useHash();

	function getDefaultIndicies(): number[] {
		return items
			.map((item, index) => {
				if (item.defaultOpen) {
					return index;
				}

				return null;
			})
			.filter(notEmpty);
	}

	// Automatically open accordion item if user clicks a anchor link related to it
	React.useEffect(() => {
		if (hash) {
			const id = hash.replace("#", "");
			const i = items.findIndex((item) => item.id === id);
			if (i !== -1) {
				setIndices([i]);
			}
		}
	}, [items, hash]);

	function toggleItem(toggledIndex: number) {
		spTrackWebInteraction({
			object: "accordion",
			action: indices.includes(toggledIndex) ? "close" : "open",
			value: items[toggledIndex].title,
		});

		if (indices.includes(toggledIndex)) {
			setIndices(
				indices.filter((currentIndex) => currentIndex !== toggledIndex)
			);
		} else {
			if (multiple) {
				// behaviour for multiple collapsible accordion
				setIndices([...indices, toggledIndex].sort());
			} else {
				// behaviour for single collapsible accordion
				setIndices([toggledIndex]);
			}
		}
	}

	return (
		<ReachAccordion collapsible index={indices} onChange={toggleItem}>
			{items.map((item, index) => {
				const isOpen = indices.includes(index);

				return (
					<ReachAccordionItem
						// show last item border when having only 1 item
						// otherwise last item of shouldn't have bottom border
						className={clsx("bg-white dark:bg-transparent", {
							"border-stroke border-b":
								items.length === 1 || index < items.length - 1,
						})}
						key={index}
						id={item.id}
					>
						<AccordionButton className="flex w-full items-center justify-between gap-5 px-5 py-6 text-left">
							<div className="flex items-center gap-3">
								{variant == "sidebar" ? (
									<Paragraph size="body-small">
										{item.title}
									</Paragraph>
								) : (
									<>
										{item.iconImage &&
										item.iconImage?.src ? (
											<Image
												className="max-w-[24px]"
												{...item.iconImage}
											/>
										) : null}
										<Paragraph fontWeight="font-medium">
											{item.title}
										</Paragraph>
									</>
								)}
							</div>
							<div className="flex-shrink-0">
								{isOpen ? (
									<Icon
										name="chevron-up"
										color="arrow"
										className="h-[12px] w-[12px]"
									/>
								) : (
									<Icon
										name="chevron-down"
										color="arrow"
										className="h-[12px] w-[12px]"
									/>
								)}
							</div>
						</AccordionButton>
						<AccordionPanel className="px-5 pb-5">
							{typeof item.panel === "string" ? (
								<Paragraph color="secondary">
									{item.panel}
								</Paragraph>
							) : (
								item.panel
							)}
						</AccordionPanel>
					</ReachAccordionItem>
				);
			})}
		</ReachAccordion>
	);
}
